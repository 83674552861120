import XLSX from "sheetjs-style";
import { customAlphabet } from "nanoid";
import CryptoJS from "crypto-js";
import axios from "axios";

export const getTextFieldFormikProps = (formik, key) => {
  const fieldProps = formik?.getFieldProps(key);
  // console.log("FP",fieldProps)
  return {
    id: key,
    name: key,
    value: fieldProps?.value == null ? "" : fieldProps?.value,
    helpertext: formik.touched[key] && formik.errors[key],
    ...fieldProps
  };
};

export const exportToExcel = (excelData, fileName) => {
  /* pass here the json data */
  const dataObj = excelData[0];
  const keys = Object.keys(dataObj).sort();

  const ws = XLSX.utils.json_to_sheet(excelData, { header: keys });
  /* generate workbook and add the worksheet */
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  /* save to file */
  XLSX.writeFile(wb, fileName + ".xlsx");
};

export const generatePassword = (length) => {
  const characters =
    "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()";
  const pwdCharacters = customAlphabet(characters, length);
  const pwd = pwdCharacters();
  return pwd;
};

export const generateRand = (length) => {
  const characters =
    "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const pwdCharacters = customAlphabet(characters, length);
  const rand = pwdCharacters();
  return rand;
};

export const generateOrderId = (length) => {
  const characters = "1234567890";
  const id = customAlphabet(characters, length);
  const rand = id();
  return `LOGS-${rand}`;
};

export const encryptor = (value) => {
  const secretPass = "LXOkGhSZPGL4UfGWT2EtE2BWOY";
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    secretPass
  ).toString();
  return data;
};

export const decryptor = (value) => {
  const secretPass = "LXOkGhSZPGL4UfGWT2EtE2BWOY";
  const bytes = CryptoJS.AES.decrypt(value, secretPass);
  const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return data;
};

export const FormatNumber = (number) => {
  return number.toLocaleString(undefined, {
    maximumFractionDigits: 2
  });
};

export const FormatCurrency = (amount) => {
  const formattedNumber = amount.toLocaleString(undefined, {
    maximumFractionDigits: 2
  });
  return `NGN ${formattedNumber}`;
};

export const paystackCharges = (amount) => {
  let result;
  if (amount < Number(2500)) {
    result = (Number(amount) * Number(1.5)) / 100;
  } else {
    const percentageResult = (Number(amount) * Number(1.5)) / 100;
    const charge = percentageResult + 100;
    result = charge < 2000 ? charge : 2000;
  }
  return result;
};

export const flutterwaveCharges = (amount) => {
  let result;
  if (Number(amount) < Number(250)) {
    result = Number(0.5);
  } else if (Number(amount) >= Number(250) && amount <= Number(999.99)) {
    result = Number(1);
  } else if (Number(amount) >= Number(1000) && amount <= Number(4999.99)) {
    result = Number(5);
  } else if (Number(amount) >= Number(5000)) {
    result = Number(25);
  } else {
    result = (Number(amount) * Number(1.4)) / 100;
    // const charge = percentageResult;
    // result = charge < 2000 ? charge : 2000;
  }
  return result;
};

export const debounce = (func, wait) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), wait);
  };
};

export async function downloadDataToTxt(type, credentials) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}download/logsToTxt`,
      {
        jsonData: JSON.stringify(credentials)
      },
      {
        responseType: "blob" // Important: handle the response as a blob
      }
    );

    // Create a URL for the blob and download the file
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/plain" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${type}.txt` || "log.txt"); // Set the default file name
    document.body.appendChild(link);
    link.click();

    // Clean up after download
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    console.log("File downloaded successfully");
    return `${type} log file downloaded successfully!`;
  } catch (error) {
    console.error("Error downloading file:", error);
  }
}

export async function downloadEncryptedDataToTxtUsingPost(type, credentials) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}download/encrypted/logsToTxt`,
      {
        jsonData: JSON.stringify(credentials)
      },
      {
        responseType: "blob", // Important: handle the response as a blob
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    // Create a URL for the blob and download the file
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/plain" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${type}.txt` || "log.txt"); // Set the default file name
    document.body.appendChild(link);
    link.click();

    // Clean up after download
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    console.log("File downloaded successfully");
    return `${type} log file downloaded successfully!`;
  } catch (error) {
    console.error("Error downloading file:", error);
  }
}
